var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: { title: _vm.headerText, icon: "check", "is-visible": true },
      scopedSlots: _vm._u(
        [
          _vm.isPledgePlaced
            ? {
                key: "additionalInfo",
                fn: function () {
                  return [
                    _c("div", { staticClass: "_flex _mt-3" }, [
                      _c("div", {
                        staticClass: "_fa _fa--info _tc--accent _mr-1",
                      }),
                      _vm._v(" "),
                      _c("div", {
                        attrs: {
                          "v-html":
                            _vm.Resources.ProjectHome
                              .AddToCartSuccessSubmitChangesHint,
                        },
                      }),
                    ]),
                  ]
                },
                proxy: true,
              }
            : null,
          {
            key: "actions",
            fn: function () {
              return [
                _vm._t(
                  "actions",
                  function () {
                    return [
                      _c(
                        "a",
                        {
                          staticClass:
                            "gfu-link gfu-link--accent gfu-link--inline",
                          attrs: {
                            href: "",
                            "data-qa": "modal-button:ContinueShopping",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.close.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .AddToCartSuccessContinueBrowsingButton
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            enabled: !_vm.canProceedToCheckout,
                            text: _vm.canProceedToCheckoutErrorMessage,
                          },
                        },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                enabled: _vm.canProceedToCheckout,
                                theme: "accent",
                                width: "wide",
                                "data-qa": "modal-button:Checkout",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.submitButtonText))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  { close: _vm.close, submit: _vm.submit }
                ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c("div", { class: _vm.cardClasses }, [
        _c("div", { staticClass: "gfu-embed gfu-embed--1x1 _flex-basis-50" }, [
          _c("img", {
            staticClass: "gfu-embed__item _rounded--common",
            attrs: { src: _vm.imageUrl, alt: _vm.productName },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "_flex-basis-50" }, [
          _c("div", { staticClass: "_pa-4" }, [
            _c(
              "h3",
              {
                staticClass: "gfu-heading",
                attrs: { "data-qa": "product-name" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.productName) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "_mt-1 _tc--light",
                attrs: { "data-qa": "quantity-added" },
              },
              [_vm._v("Quantity: " + _vm._s(_vm.quantity))]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.shouldShowInstallmentsInformation
        ? _c("add-product-to-cart-installments-information", {
            attrs: { "data-qa": "stretch-pay-info" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      !_vm.isLegacy
        ? _c("product-recommendation-slider", {
            attrs: {
              section: "upsells-success-modal",
              "slides-visible-on-desktop": 2,
              "project-id": _vm.projectId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }