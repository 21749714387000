import '@/Polyfills';
import Vue from 'vue';
import Resources from '@/Resources.js';
import { PiniaVuePlugin } from 'pinia';
import VeeValidate from 'vee-validate';
import { apiClient } from '@/Clients/ApiClient';
import Firebase from './Firebase.js';
import { initializeAuthCheck } from '@/Utils/Auth';
import { displayMode, displayModeTypes } from '@/Utils/PwaUtility';
import Formatter from '@/Utils/Formatter';
import notify from '@/Plugins/Notify.js';
import breakpoints from '@/Plugins/Breakpoints';
import displayModes from '@/Plugins/DisplayModes';
import ToastWrapper from '@/Components/ToastWrapper.vue';
import Localize from '@/Components/Utils/Localize.js';
import HelpUrls from '@/Data/HelpUrls.json';

window.Vue = Vue;
window.Vue.config.productionTip = false;
window.VeeValidate = VeeValidate;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js', { updateViaCache: 'none' } );
}

// Add Resources to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        Resources: {
            get() {
                return Resources;
            },
        },
    });
});

// Add pinia to all Vue instances
Vue.use(PiniaVuePlugin);

// Add VeeValidate to all Vue instances
Vue.use(VeeValidate);

// Add Firebase to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        Firebase: {
            get() {
                return Firebase;
            },
        },
    });
});

// Add $http to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $http: {
            get() {
                return apiClient;
            },
        },
    });
});

// Add HelpUrls to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $helpUrls: {
            get() {
                return HelpUrls;
            },
        },
    });
});

// Add localize component as global component
Vue.component('localize', Localize);

// Add $format to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $format: {
            get() {
                return Formatter.format;
            },
        },
    });
});

// Add $breakpoint to all Vue instances, has to be before notify plugin
Vue.use(breakpoints);
Vue.use(displayModes);
// Add $notify to all Vue instances
Vue.use(notify, { extensionName: '$notify', components: [ToastWrapper] });

if (displayMode() === displayModeTypes.pwa) {
    initializeAuthCheck();
}

// Prevent PWA prompt
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
});