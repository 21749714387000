let config = {
    currentUser: {
        hasAccessToAdminMode: false,
    },
    http: {
        root: null,
        performanceMarketing: null,
    },
    comments: {
        tagPattern: null,
    },
    externalUrls: {
        careerUrl: null,
        helpUrl: null,
        dynamicCurrencyConversionArticleUrl: null,
        stablePledgeInformationBackerHelpUrl: null,
        stablePledgeInformationCreatorHelpUrl: null,
        stablePledgeSecureYourPledgeArticleUrl: null,
        shippingGuideArticleUrl: null,
        stripeScriptUrl: null,
        reCaptchaPrivacyPolicyUrl: null,
        reCaptchaTermsUrl: null,
    },
    navigation: {
        aboutUsUrl: null,
        accountSettingsUrl: null,
        backersTermsOfServiceUrl: null,
        becomeACreatorUrl: null,
        marketingServicesUrl: null,
        blogUrl: null,
        contactUrl: null,
        cookieSettingsUrl: null,
        communityGuidelinesUrl: null,
        createAccountUrl: null,
        createAccountUrlWithReturn: null,
        createCreatorAccountUrl: null,
        creatorDigitalTermsOfServiceUrl: null,
        creatorProblemBusinessContactUrl: null,
        creatorTermsOfServiceUrl: null,
        crowdfundingGuideUrl: null,
        currentActionUrl: null,
        feesUrl: null,
        homeUrl: null,
        loginUrlWithReturn: null,
        loginUrl: null,
        logoutUrl: null,
        myProjectsUrl: null,
        subscriptionManagementUrl: null,
        privacyPolicyUrl: null,
        resetPasswordUrl: null,
        resetPasswordRequestSentUrl: null,
        performResetPasswordUrl: null,
        searchUrl: null,
        termsOfServiceUrl: null,
        termsOfServiceIndexUrl: null,
        userProfileUrl: null,
        joinCallbackUrl: null,
        storesTermsOfServiceUrl: null,
        joinProjectUrl: null,
    },
    projectNavigation: {
        backProjectUrl: null,
        creatorUrl: null,
        creatorAvatarUrl: null,
        changeCurrencyUrl: null,
        projectContactUrl: null,
        projectHome: null,
        refundAndCancellationPolicyUrl: null,
        refundAndCancellationPolicyText: null,
        stretchGoalsUrl: null,
    },
    projectNavigationNames: {
        refundAndCancellationPolicyName: null,
    },
    pushNotifications: {
        apiKey: null,
        appID: null,
        authDomain: null,
        projectID: null,
        messagingSenderID: null,
        storageBucket: null,
        vapidKey: null,
    },
    cdn: {
        enableCdn: false,
        transformations: {},
    },
    debug: false,
    environment: null,
    paymentProviders: {
        adyen: {
            clientKey: null,
            gatewayMerchantId: '',
            merchantName: '',
            useLiveMode: false,
        },
        googlePay: {
            merchantId: '',
            isEnabled: false,
        },
        applePay: {
            isEnabled: false,
        },
    },
    language: {
        currentLanguageIsoCode: null,
        languageSelectorEnabled: false,
    },
    externalScripts: {
        googlePayUrl: '',
        applePayUrl: '',
        googleSignInUrl: '',
        facebookSignInUrl: '',
        reCaptchaUrl: '',
        youTubeApiScriptUrl: '',
    },
    userIdentityProviders: {
        facebook: {
            appID: null,
            isEnabled: false,
        },
        google: {
            clientID: null,
            isEnabled: false,
        },
    },
    orderRules: {
        availableOrderStagesForCreatorChange: null,
        orderPaymentStatusesForCrowdfundingFilters: null,
        orderPaymentStatusesForExternalPMFilters: null,
        orderPaymentStatusesForPreorderFilters: null,
        orderPaymentStatusesForStoreFilters: null,
        orderPaymentStatusForV1ProjectFilters: null,
        orderStagesForCrowdfundingFilters: null,
        orderStagesForExternalPMFilters: null,
        orderStagesForPreorderFilters: null,
        orderStagesForStoreFilters: null,
        orderStagesForV1ProjectFilters: null,
    },
    reCaptchaProvider: {
        siteKey: '',
        isEnabled: false,
    },
    antiForgeryTokenKey: null,
};

class ConfigInitializer {
    static configIsInitialized = false;

    static initializeConfig() {
        if (ConfigInitializer.configIsInitialized)
            return;

        if (window.__CONFIG__) {
            config = window.__CONFIG__;
        }

        ConfigInitializer.configIsInitialized = true;
    }
}

ConfigInitializer.initializeConfig();

export default config;
export const navigation = config.navigation;
export const adyenConfig = config.paymentProviders.adyen;
export const googlePayConfig = config.paymentProviders.googlePay;
export const applePay = config.paymentProviders.applePay;
export const externalScripts = config.externalScripts;
export const userIdentityProviders = config.userIdentityProviders;
export const orderRules = config.orderRules;
export const reCaptchaProvider = config.reCaptchaProvider;
export const externalUrls = config.externalUrls;
export const pushNotifications = config.pushNotifications;