var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-payment-form" },
    [
      !_vm.isOrderPayable
        ? _c("adyen-create-order-without-payment", {
            attrs: {
              "order-code": _vm.orderCode,
              "anti-forgery-token": _vm.antiForgeryToken,
              "form-action": _vm.formAction,
              "back-action": _vm.backAction,
              "validation-message": _vm.validationMessage,
              "is-valid": _vm.isValid,
              "order-state": _vm.orderState,
            },
          })
        : _vm.isOrderPayable
        ? _c("adyen-order-payment-options", {
            attrs: {
              "project-id": _vm.projectContext.projectID,
              "project-phase": _vm.projectContext.project.phase,
              "project-type": _vm.projectContext.project.type,
              "is-instant": true,
              "stored-card": _vm.storedCard,
              "checkout-mode": _vm.checkoutMode,
              "effective-payable-amount": _vm.effectivePayableAmount,
              "currency-short-name": _vm.currencyShortName,
              "order-code": _vm.orderCode,
              "order-validation-id": _vm.orderCode,
              "require-accept-digital-delivery":
                _vm.requireAcceptDigitalDelivery,
              "payment-type": _vm.paymentType,
              reason: _vm.reason,
              "is-in-test-mode": _vm.isInTestMode,
              "data-qa": "payment-section",
            },
            on: {
              "payment-error": _vm.onPaymentError,
              "payment-method-change": _vm.onPaymentMethodChange,
              "toggle-payment-group-visibility-change":
                _vm.onTogglePaymentGroupVisibilityChange,
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function ({ onSubmit }) {
                  return [
                    _c("div", { staticClass: "gfu-box-actions" }, [
                      _c("div", { staticClass: "gfu-box-actions__item" }, [
                        _vm.isBackButtonVisible
                          ? _c(
                              "a",
                              {
                                staticClass: "gfu-link gfu-link--accent",
                                attrs: { href: _vm.backAction },
                                on: {
                                  click: function ($event) {
                                    return _vm.onBackButtonClick(
                                      _vm.backAction,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("base-icon", {
                                  staticClass: "_fl _lhb",
                                  attrs: { name: "chevron-left" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "_ml-1",
                                    attrs: {
                                      "data-qa": "create-order-btn:Back",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.Checkout
                                          .CheckoutGoBackLink
                                      ) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "gfu-box-actions__item _ml-a" },
                        [
                          _c(
                            "tooltip-button",
                            {
                              attrs: {
                                "tooltip-text": _vm.tooltipText,
                                enabled: _vm.isPaymentButtonEnabled,
                                "data-qa": "create-order-btn:Submit",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return onSubmit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.Checkout.CheckoutPayButton
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "data-qa": "create-order-btn:Price",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.amount))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.installmentsSelected
                            ? _c("installments-info-box", {
                                attrs: { amount: _vm.orderAmountWithSymbol },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }