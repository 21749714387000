import Config from '@/Config';
import InitialState from '@/InitialState';

const {
    loginUrl,
    performResetPasswordUrl,
    resetPasswordUrl,
    resetPasswordRequestSentUrl,
    createAccountUrl,
    privacyPolicyUrl,
    loginUrlWithReturn,
    createAccountUrlWithReturn,
    joinCallbackUrl,
    joinProjectUrl,
} = Config.navigation;

// the strings below are later evaluated as RegExp
const unrestrictedLinksRegexStrings = [
    loginUrl,
    performResetPasswordUrl,
    resetPasswordUrl,
    resetPasswordRequestSentUrl,
    createAccountUrl,
    privacyPolicyUrl,
    loginUrlWithReturn,
    createAccountUrlWithReturn,
    joinCallbackUrl,
    `${joinProjectUrl}$`,
];

// maping strings array to RegExp array
const configuredRegexArray = unrestrictedLinksRegexStrings.filter(e => !!e).map(e => new RegExp(e));

// check if provided string complies with at least one RegExp
function validatePathname(pathname) {
    return configuredRegexArray.reduce((acc, val) => acc || val.test(pathname), false);
}

function isAuthenticated() {
    return InitialState.userContext.isAuthenticated;
}

function redirectToLogin() {
    window.location.href = loginUrl;
}

function checkAuthentication() {
    if (!isAuthenticated() && !validatePathname(window.location.pathname)) {
        redirectToLogin();
    }
}

function overrideHistoryMethods() {
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function() {
        const result = originalPushState.apply(history, arguments);
        checkAuthentication();
        return result;
    };

    history.replaceState = function() {
        const result = originalReplaceState.apply(history, arguments);
        checkAuthentication();
        return result;
    };
}

export function initializeAuthCheck() {
    overrideHistoryMethods();
    checkAuthentication();
    window.addEventListener('popstate', checkAuthentication);
}