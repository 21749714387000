var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-header-logo",
      attrs: { title: "Gamefound", "data-qa": "main-navbar:Logo" },
    },
    [
      _vm.isBackButtonVisible
        ? _c("base-icon", {
            attrs: { size: "lg", name: "arrow-left" },
            on: { click: _vm.goBack },
          })
        : _c(
            "a",
            { attrs: { href: _vm.url } },
            [
              _c("base-image", {
                staticClass: "gfu-header-logo__icon",
                attrs: {
                  src: "/content/images/site-icon.svg",
                  alt: "Gamefound",
                },
              }),
              _vm._v(" "),
              _c("base-image", {
                staticClass: "gfu-header-logo__full",
                attrs: {
                  src: "/content/images/site-logo.svg",
                  alt: "Gamefound",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }