import VueRouter from 'vue-router';

const location = window.location;
const history = window.history;
const hash = location.hash;

const routes = [];
const savedScrollPositions = {};

// Redirect to admin page if hash starts with #/admin
if (hash.startsWith('#/admin')) {
    location.href = hash.replace('#', '');
}

const router = new VueRouter({
    routes,
    mode: 'abstract',
    scrollBehavior(to, from, savedPosition) {
        if (to.matched.some(record => record.meta.noScroll) || to.query.focusFields) {
            return null;
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
});

router.previousRouteReplaced = false;
router.previousRoute = null;
router.originalResolve = router.resolve;
router.originalReplace = router.replace;

// NOTE: hack for abstract+hash mode hybrid
router.resolve = function () {
    const originalResult = this.originalResolve.apply(router, arguments);
    originalResult.href = `#${originalResult.href}`;
    return originalResult;
};

router.replace = function () {
    router.previousRouteReplaced = true;
    return this.originalReplace.apply(router, arguments);
};

router.beforeEach((to, from, next) => {
    if (savedScrollPositions[to.fullPath] && (from?.meta && !from.meta.noScroll)) {
        window.scrollTo(0, savedScrollPositions[to.fullPath].y);
    }

    router.previousRoute = from;
    next();
});

router.afterEach((to, from) => {
    if (from?.meta && !from.meta.noScroll && !!from.name) {
        savedScrollPositions[from.fullPath] = { y: window.pageYOffset };
    }

    if (to) {
        const hashPath = getCurrentHashPath();
        const pathname = location.pathname;
        const search = location.search;

        if (hashPath !== to.fullPath) {
            if (to.name === null) {
                history.pushState({ from: from.fullPath, to: to.fullPath }, null, `${pathname}${search}`);
            }
            else {
                const params = [{ from: from.fullPath, to: to.fullPath }, null, `${pathname}${search}#${to.fullPath}`];
                // hack for abstract+hash mode hybrid until vue router show replace or push info in guards
                if (router.previousRouteReplaced) {
                    history.replaceState(...params);
                }
                else {
                    history.pushState(...params);
                }
            }
        }

        router.previousRouteReplaced = false;
    }
});
registerHashWatchToRouter(router);

function getCurrentHashPath() {
    const path = location.hash.replace('#', '');
    return path.length > 0 ? path : null;
}

function registerHashWatchToRouter(router) {
    if (location.hash) {
        router.push(getCurrentHashPath(), () => { });
    }
    window.addEventListener('hashchange', () => {
        const hashPath = getCurrentHashPath();
        const routerPath = router.currentRoute.fullPath;
        if (hashPath !== routerPath && history.state === null) {
            router.push(hashPath ? hashPath : '/', () => { });
        }
    }, false);
    window.addEventListener('popstate', () => {
        const hashPath = getCurrentHashPath();
        const routerPath = router.currentRoute.fullPath;
        if (hashPath !== routerPath && history.state !== null) {
            router.replace(hashPath ? hashPath : '/', () => { });
        }
    }, false);
}

export default router;
