import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility';
import { isInstalledPWA } from '@/Utils/Browser';

const iOSApp = 'iOS App Store';

export const displayModeTypes = {
    web: 'web',
    pwa: 'pwa',
};

export function isIOSApp() {
    const appPlatformCookie = CookiesUtility.get(cookieNames.appPlatform);

    return appPlatformCookie === iOSApp;
}

export function isPWA() {
    return isIOSApp() || isInstalledPWA();
}

export function displayMode() {
    if (isPWA()) {
        return displayModeTypes.pwa;
    }
    else {
        return displayModeTypes.web;
    }
}