var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notificator", {
        ref: "notifyImported",
        staticClass: "_mt-3",
        attrs: { singular: true, "can-close": false },
      }),
      _vm._v(" "),
      _c(
        "box",
        { attrs: { "data-qa": "project-user-pledge:CurrentPledgeBox" } },
        [
          _c("balloon-actions", {
            attrs: {
              actions: _vm.actions,
              "balloon-style-props": _vm.balloonStyleProps,
              mode: _vm.balloonActionsModes.grouped,
              "data-qa": "project-user-pledge:BalloonActions",
            },
          }),
          _vm._v(" "),
          _vm.isPledgePlaced
            ? _c("project-user-pledge-meta", {
                attrs: {
                  pledge: _vm.metaPledgeModel,
                  "is-open-for-orders": _vm.isOpenForOrders,
                  "is-editing": _vm.canRevertChanges,
                  "is-expanded": _vm.isExpanded,
                  "is-expandable": _vm.isExpandable,
                  "can-proceed-to-checkout": _vm.canProceedToCheckout,
                  labels: _vm.labels,
                },
                on: {
                  "cta-triggered": _vm.onMetaCtaTriggered,
                  expand: _vm.onToggleMore,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isExpandable || _vm.isExpanded
            ? [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPledgePlaced,
                      expression: "isPledgePlaced",
                    },
                  ],
                  staticClass: "gfu-box-divider _mb-3",
                }),
                _vm._v(" "),
                _vm.allItemsRemoved
                  ? [
                      _c(
                        "div",
                        { staticClass: "gfu-table" },
                        [
                          _c(
                            "empty-state",
                            {
                              attrs: {
                                description:
                                  _vm.Resources.YourPledge
                                    .EmptyPledgeRemovedAllItems,
                                "show-divider": false,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "_flexcntr _flexwrap" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--micro _ttl gfu-btn--thin gfu-empty-state-action _tlc",
                                      attrs: {
                                        href: _vm.addNewItemsUrl,
                                        "data-qa":
                                          "your-pledge-empty-state:AddItems",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "_fa gfu-btn__icon _fa--plus",
                                      }),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.Resources.YourPledge
                                              .AddItemsButton
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "icon-base-button",
                                        {
                                          staticClass:
                                            "gfu-empty-state-action _tlc",
                                          attrs: {
                                            icon: "undo",
                                            "data-qa":
                                              "your-pledge-empty-state:RevertChanges",
                                          },
                                          on: { click: _vm.revertChanges },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Resources.YourPledge
                                                .RevertChangesButton
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.pledge.canBeCanceled
                                        ? _c(
                                            "icon-base-button",
                                            {
                                              staticClass:
                                                "gfu-empty-state-action _tlc",
                                              attrs: {
                                                icon: "times",
                                                "data-qa":
                                                  "your-pledge-empty-state:CancelPledge",
                                              },
                                              on: { click: _vm.onCancelOrder },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.Resources.YourPledge
                                                    .CancelPledgeButton
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c("legacy-cart-table", {
                        key:
                          _vm.pledge.cartTableOrderCode ||
                          _vm.pledge.orderPublicID,
                        ref: "cartTable",
                        attrs: {
                          "cart-behavior": _vm.pledge.cartTableBehavior,
                          "copy-shipping-info": false,
                          "include-current-cart": _vm.pledge.includeCurrentCart,
                          "order-code": _vm.pledge.cartTableOrderCode,
                          "project-id": _vm.pledge.projectID,
                          "recalculate-order": _vm.pledge.includeCurrentCart,
                        },
                        on: {
                          "cart-loaded": _vm.onCartLoaded,
                          "cart-changed": _vm.onCartChanged,
                          "table-emptied": _vm.onTableEmptied,
                          "cart-invalid": function ($event) {
                            return _vm.onCartValidStatusChange(false)
                          },
                          "cart-valid": function ($event) {
                            return _vm.onCartValidStatusChange(true)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.isProductRecommendationsVisible
                        ? _c("product-recommendation-slider", {
                            attrs: {
                              "order-code": _vm.orderCode,
                              "project-id": _vm.projectContext.projectID,
                              section: "upsells-your-pledge",
                              "slides-visible-on-desktop": 3,
                              quantity: 9,
                            },
                            on: { "product-added": _vm.onEditPledge },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("legacy-order-summary-list", {
                        attrs: { "order-id": _vm.orderId },
                      }),
                      _vm._v(" "),
                      _vm.isPledgePlaced
                        ? [
                            _c("order-details-summary", {
                              attrs: {
                                "order-details": _vm.pledge.pledgeDetails,
                                "order-id": _vm.pledge.orderID,
                                "can-remove-unpaid-items":
                                  _vm.pledge.canRemoveUnpaidItems,
                                "can-add-more-items": _vm.pledge.canAddNewItems,
                                "can-resume-payment":
                                  _vm.pledge.canResumePayment,
                                "can-cancel-order": _vm.pledge.canBeCanceled,
                              },
                              on: {
                                "cancel-order": _vm.onCancelOrder,
                                "resume-order": _vm.onResumeOrder,
                                "remove-unpaid-items": _vm.removeUnpaidItems,
                                "add-items-to-order": _vm.onAddItemsToOrder,
                                "edit-pledge": _vm.onEditPledge,
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasFullAddress
                              ? _c("order-details-shipping", {
                                  attrs: {
                                    "billing-address": _vm.billingAddress,
                                    "shipping-address": _vm.shippingAddress,
                                    "can-change-shipping-address":
                                      _vm.pledge.canChangeShipping,
                                  },
                                  on: {
                                    "change-shipping": _vm.onChangeShipping,
                                  },
                                })
                              : _vm.hasPartialAddress
                              ? _c("order-partial-details-shipping", {
                                  attrs: {
                                    "shipping-address": _vm.shippingAddress,
                                    "shipping-model":
                                      _vm.pledge.pledgeDetails.shippingModel,
                                    "can-change-shipping-address":
                                      _vm.pledge.canChangeShipping,
                                  },
                                  on: {
                                    "change-shipping": _vm.onChangeShipping,
                                  },
                                })
                              : _vm.hasAddressHidden
                              ? [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "gfu-heading gfu-heading--top-divider _mt-3 _mb-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Resources.YourPledge
                                            .ShippingSectionHeader
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", {
                                      staticClass: "_fa _fa--info",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.shippingInfoNotification) +
                                        "\n                        "
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPaymentOverview
                              ? [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "gfu-heading gfu-heading--top-divider _mt-3",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.YourPledge
                                              .PaymentMethodSectionHeader
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isPaymentMethodInstant
                                    ? [
                                        _c(
                                          "localize",
                                          {
                                            staticClass:
                                              "gfu-bt gfu-bt--b2 _mb-3 _mt-2",
                                            attrs: {
                                              tag: "div",
                                              resource:
                                                _vm.Resources.YourPledge
                                                  .PaymentMethodInfoInstantPaymentText,
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "_tc--accent",
                                                attrs: {
                                                  href: _vm.projectContactUrl,
                                                  "format-key": "0",
                                                  "data-qa":
                                                    "your-pledge-link:ContactTheCreator",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.YourPledge
                                                      .PaymentMethodInfoInstantPaymentTextLink
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "_mt-4 _pa-4 _bg-color-light _brad--common",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "_flex _gap-2 _mb-4 _ai-c",
                                              },
                                              [
                                                _c("base-image", {
                                                  attrs: {
                                                    src: _vm
                                                      .paymentOverviewDetails
                                                      .imageUrl,
                                                    alt: _vm
                                                      .paymentOverviewDetails
                                                      .name,
                                                    height: "20",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "_tc--light" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .paymentOverviewDetails
                                                          .name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "gfu-list _flexcol _gap-2",
                                              },
                                              _vm._l(
                                                _vm.paymentMethodDisclaimer,
                                                function (item) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: item.iconName,
                                                      staticClass: "_flex",
                                                    },
                                                    [
                                                      _c("base-icon", {
                                                        staticClass:
                                                          "_mr-2 _tc--accent _shrink-0 _py-h",
                                                        attrs: {
                                                          size: "lg",
                                                          "is-fixed-width": true,
                                                          name: item.iconName,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "_flex _ai-c",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.paragraph
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-bt gfu-bt--b2 _mb-3 _mt-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.paymentMethodInfo) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.changeCreditCard
                                          ? _c(
                                              "credit-card-overview",
                                              {
                                                attrs: {
                                                  "image-url":
                                                    _vm.paymentOverview
                                                      .cardImageUrl,
                                                  name: _vm.paymentOverview
                                                    .cardName,
                                                  "masked-number":
                                                    _vm.paymentOverview
                                                      .cardMaskedNumber,
                                                  "payment-data-source":
                                                    _vm.paymentOverview
                                                      .paymentDataSource,
                                                },
                                              },
                                              [
                                                _vm.paymentOverview
                                                  .canChangeCreditCard
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "gfu-link gfu-link--accent _mt-1",
                                                        attrs: {
                                                          href: "#",
                                                          "data-qa":
                                                            "your-pledge-link:ChangeCard",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.toggleCardChange.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.Resources
                                                              .YourPledge
                                                              .ChangePaymentMethodButton
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm.paymentOverview
                                              .canChangeCreditCard
                                          ? [
                                              _c(
                                                "adyen-order-payment-options",
                                                {
                                                  attrs: {
                                                    "project-id":
                                                      _vm.projectContext
                                                        .projectID,
                                                    "project-phase":
                                                      _vm.projectContext.project
                                                        .phase,
                                                    "project-type":
                                                      _vm.projectContext.project
                                                        .type,
                                                    "is-instant": false,
                                                    "require-accept-tos": false,
                                                    "effective-payable-amount":
                                                      _vm.pledge
                                                        .effectivePayableAmount,
                                                    "currency-short-name":
                                                      _vm.pledge
                                                        .currencyShortName,
                                                    "order-code":
                                                      _vm.pledge.orderCode,
                                                    "order-id":
                                                      _vm.pledge.orderID,
                                                    "payment-type":
                                                      _vm.paymentType,
                                                    reason:
                                                      _vm.paymentMethodReason,
                                                  },
                                                  on: {
                                                    "payment-error":
                                                      _vm.onPaymentError,
                                                    "payment-method-change":
                                                      _vm.onPaymentMethodChange,
                                                    "payment-method-changed":
                                                      _vm.onPaymentMethodChanged,
                                                    "toggle-payment-group-visibility-change":
                                                      _vm.onTogglePaymentGroupVisibilityChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "actions",
                                                        fn: function ({
                                                          onSubmit,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gfu-box-actions _mt-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "_ml-a",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "gfu-link _mr-3",
                                                                        attrs: {
                                                                          href: "#",
                                                                          "data-qa":
                                                                            "create-order-btn:Cancel",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.toggleCardChange.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .Resources
                                                                              .YourPledge
                                                                              .CancelPaymentMethodChangeButton
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "base-button",
                                                                      {
                                                                        staticClass:
                                                                          "gfu-btn--wide gfu-btn--soft",
                                                                        attrs: {
                                                                          theme:
                                                                            "accent",
                                                                          "data-qa":
                                                                            "create-order-btn:Submit",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            onSubmit,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                    " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .Resources
                                                                                .YourPledge
                                                                                .SavePaymentMethodChangeButton
                                                                            ) +
                                                                            "\n                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    969421770
                                                  ),
                                                }
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                _vm._v(" "),
                _vm.promoItem
                  ? _c("project-promo-item", {
                      staticClass:
                        "gfu-isle gfu-isle--primary-light _pa-2 _mt-3",
                      attrs: {
                        "image-size": 48,
                        "in-context": false,
                        "data-qa": "your-pledge-project-promo-item",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isUnfinishedOrder
                  ? _c("div", { staticClass: "gfu-box-actions" }, [
                      _vm.canRevertChanges
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "gfu-box-actions__item gfu-box-actions__item--link",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: {
                                    "data-qa": "your-pledge-button:Revert",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.revertChanges.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.YourPledge
                                          .RevertChangesButtonLong
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAddMoreButton
                        ? _c(
                            "div",
                            { staticClass: "gfu-box-actions__item" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    theme: "primary",
                                    display: "fixed",
                                    shape: "default",
                                    "data-qa": "your-pledge-button:AddItems",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onAddItemsToOrder.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.Resources.YourPledge.AddMoreButton
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "gfu-box-actions__item _ml-a" },
                        [
                          _vm.canProceedToCheckout
                            ? _c("go-to-checkout", {
                                attrs: {
                                  "redirect-on-alternative-action": false,
                                  "continue-url": _vm.cartSummary.checkoutUrl,
                                  "order-code": _vm.pledge.cartTableOrderCode,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ onInvoke }) {
                                        return [
                                          _c(
                                            "base-button",
                                            {
                                              attrs: {
                                                enabled:
                                                  _vm.canProceedToCheckout,
                                                theme: "accent",
                                                display: "block",
                                                "data-qa":
                                                  "your-pledge-checkout-btn",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return onInvoke.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.YourPledge
                                                      .CheckoutButton
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  896329351
                                ),
                              })
                            : _c(
                                "tooltip",
                                {
                                  attrs: {
                                    text: _vm.disabledCheckoutTooltipText,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gfu-btn gfu-btn--soft gfu-btn--accent gfu-btn--block",
                                      attrs: {
                                        type: "button",
                                        disabled: "disabled",
                                        "data-qa": "your-pledge-checkout-btn",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.YourPledge
                                              .CheckoutButton
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ])
                  : _vm.canSetShipping
                  ? _c("div", { staticClass: "gfu-box-actions" }, [
                      _c(
                        "div",
                        { staticClass: "gfu-box-actions__item _ml-a" },
                        [
                          _c("go-to-checkout", {
                            attrs: {
                              "redirect-on-alternative-action": false,
                              "continue-url":
                                _vm.cartCandidate.manageShippingUrl,
                              "order-code": _vm.cartCandidate.orderCode,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ onInvoke }) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "gfu-btn gfu-btn--soft gfu-btn--accent gfu-btn--block",
                                          attrs: {
                                            href: _vm.cartCandidate
                                              .manageShippingUrl,
                                            "data-qa":
                                              "your-pledge-checkout-btn",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return onInvoke.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.YourPledge
                                                  .SetShippingButton
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2415265915
                            ),
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isPaymentHistoryModalOpen
            ? _c("pledge-payment-history-modal", {
                attrs: {
                  "order-id": _vm.paymentHistoryOrderId,
                  "pledge-id": _vm.pledge.orderPublicID,
                  "is-open": _vm.isPaymentHistoryModalOpen,
                },
                on: { "action-close": _vm.onPaymentHistoryModalClose },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.pledge.pledgeDetails
            ? _c("order-stage-history-modal", {
                attrs: {
                  "is-open": _vm.isOrderStageHistoryModalOpen,
                  "order-public-id": _vm.pledge.pledgeDetails.orderPublicID,
                  "order-state-history":
                    _vm.pledge.pledgeDetails.orderStateHistory,
                },
                on: { "action-close": _vm.onOrderStageHistoryModalClose },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }