import { defineStore } from 'pinia';
import ProjectsService from '@/Services/ProjectsService';
import { apiClient } from '@/Clients/ApiClient.js';

const projectService = new ProjectsService(apiClient);

export const useProjectAdditionalInfoStore = defineStore({
    id: 'projectAdditionalInfo',
    state: () => ({
        // TODO: Rewrite projectAdditionalInfo to something smaller
        projectAdditionalInfo: null,
    }),
    actions: {
        loadProjectAdditionalInfo(projectId) {
            if (this.projectAdditionalInfo === null || this.projectAdditionalInfo.projectID !== projectId) {
                return this.refreshProjectAdditionalInfo(projectId);
            }

            return Promise.resolve();
        },
        resetProjectAdditionalInfo() {
            this.projectAdditionalInfo = null;
        },
        async refreshProjectAdditionalInfo(projectId) {
            try {
                const response = await projectService.getProjectAdditionalInfo(projectId);

                this.projectAdditionalInfo = response.data;

                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        },
    },
});