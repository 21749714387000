import { paymentDataSources, paymentCollectionTypes } from '@/Models';
import Resources from '@/Resources.js';
import { isIOSApp } from '@/Utils/PwaUtility';

const paymentMethodTypes = {
    scheme: 'scheme',
    paywithgoogle: 'paywithgoogle',
    applepay: 'applepay',
    klarnaPayNow: 'klarna_paynow',
    ideal: 'ideal',
    bcmc: 'bcmc',
    bcmcMobile: 'bcmc_mobile',
    payPal: 'paypal',
    blik: 'blik',
    onlineBankingFI: 'ebanking_FI',
    onlineBankingPL: 'onlineBanking_PL',
    twint: 'twint',
    eps: 'eps',
    trustly: 'trustly',
    mobilePay: 'mobilepay',
};

const paymentMethodTypesExcludedFromIOS = [
    paymentMethodTypes.ideal,
    paymentMethodTypes.onlineBankingFI,
    paymentMethodTypes.onlineBankingPL,
    paymentMethodTypes.paywithgoogle,
    paymentMethodTypes.eps
];

// TODO: move to backend https://dev.azure.com/gamefound/Gamefound/_workitems/edit/21324
const availablePaymentMethodsTypes = () => {
    const types = Object.values(paymentMethodTypes);

    return isIOSApp() ? types.filter(type => !paymentMethodTypesExcludedFromIOS.includes(type)) : types;
};

export const paymentMethodImages = {
    [paymentDataSources.googlePay]: '/content/images/payments/google-pay.svg',
    [paymentDataSources.newCard]: '/content/images/payments/credit-card.svg',
    [paymentDataSources.applePay]: '/content/images/payments/apple-pay.svg',
    [paymentDataSources.klarnaPayNow]: '/content/images/payments/klarna-pay-now.svg',
    [paymentDataSources.ideal]: '/content/images/payments/logo-ideal.svg',
    [paymentDataSources.bancontactCard]: '/content/images/payments/bcmc.svg',
    [paymentDataSources.bancontactMobile]: '/content/images/payments/bcmc.svg',
    [paymentDataSources.payPal]: '/content/images/payments/logo-paypal.svg',
    [paymentDataSources.blik]: '/content/images/payments/logo-blik.svg',
    [paymentDataSources.onlineBankingFI]: '/content/images/payments/logo-paytrail.svg',
    [paymentDataSources.onlineBankingPL]: '/content/images/payments/logo-przelewy24.svg',
    [paymentDataSources.twint]: '/content/images/payments/logo-twint.svg',
    [paymentDataSources.eps]: '/content/images/payments/logo-eps.svg',
    [paymentDataSources.trustly]: '/content/images/payments/logo-trustly.svg',
    [paymentDataSources.mobilePay]: '/content/images/payments/logo-mobilepay.svg',
};

// Functions are used to get the current translation for the payment method
const paymentMethodNames = {
    [paymentDataSources.klarnaPayNow]: () => Resources.Checkout.CheckoutKlarnaPaymentMethodLabel,
    [paymentDataSources.newCard]: () => Resources.Checkout.CardPaymentMethodName,
    [paymentDataSources.onlineBankingFI]: () => Resources.Checkout.OnlineBankingFIPaymentMethodLabel,
    [paymentDataSources.onlineBankingPL]: () => Resources.Checkout.OnlineBankingPLPaymentMethodLabel,
};

const paymentMethodLabels = {
    [paymentDataSources.storedCard]: () => Resources.Checkout.SavedPaymentMethodLabel,
    [paymentDataSources.currentCard]: () => Resources.Checkout.PreviouslyUsedPaymentMethodLabel,
};

const paymentMethodLabelThemes = {
    [paymentDataSources.storedCard]: 'primary',
    [paymentDataSources.currentCard]: 'accent',
};

export const additionalPaymentMethodFormComponent = {
    [paymentDataSources.klarnaPayNow]: 'adyen-email-form',
    [paymentDataSources.ideal]: 'adyen-ideal',
    [paymentDataSources.bancontactCard]: 'adyen-bancontact-card-form',
    [paymentDataSources.blik]: 'adyen-blik-form',
    [paymentDataSources.newCard]: 'adyen-card-form',
    [paymentDataSources.onlineBankingFI]: 'adyen-email-form',
    [paymentDataSources.onlineBankingPL]: 'adyen-online-banking-p-l',
    [paymentDataSources.eps]: 'adyen-eps',
    [paymentDataSources.trustly]: 'adyen-email-form',
};

// this value is used for validation to recognize if the secure field is invalid
// it is used for custom validation logic from Adyen values in iframe
// the value has to be any string because otherwise custom validator is not fired when value is empty
// and we cannot use 'required' validation because of wrong message (we need to customize based on secureFieldErrors)
export const secureFieldIncorrectValue = 'secureFieldIncorrectValue';

export function buildPaymentMethod({
    isEnabled = false,
    name,
    paymentDataSource,
    paymentCollectionType,
    imageUrl = null,
    form = null,
    label = null,
    labelTheme = null,
    issuers,
    order = 0,
    isSuggested = false,
}) {
    return {
        isEnabled,
        name,
        paymentDataSource,
        imageUrl: imageUrl ? imageUrl : paymentMethodImages[paymentDataSource],
        form,
        label,
        labelTheme,
        issuers,
        paymentCollectionType,
        order,
        isSuggested,
    };
}

export function buildAvailablePaymentMethods(availablePaymentMethods, disabledPaymentMethods) {
    const availableMethods = availablePaymentMethods
        .filter(pm => availablePaymentMethodsTypes().includes(pm.type))
        .map(pm => {
            const dataSource = pm.paymentDataSource;
            const customName = paymentMethodNames[dataSource] ? paymentMethodNames[dataSource]() : null;
            const isEnabled = !disabledPaymentMethods.includes(dataSource);

            return buildPaymentMethod({
                isEnabled,
                isSuggested: pm.isSuggested,
                name: customName ? customName : pm.name,
                paymentDataSource: dataSource,
                form: additionalPaymentMethodFormComponent[dataSource] ?? null,
                issuers: pm.issuers,
                paymentCollectionType: pm.paymentCollectionType,
                order: paymentDataSources[dataSource].order,
                imageUrl: pm.imageUrl,
                label: paymentMethodLabels[dataSource] ? paymentMethodLabels[dataSource]() : null,
                labelTheme: paymentMethodLabelThemes[dataSource] ?? null,
            });
        })
        .filter(({ isEnabled }) => isEnabled);
    return availableMethods.sort((a, b) => a.order - b.order);
}

export function buildPaymentMethodDisclaimer(paymentCollectionType) {
    if (paymentCollectionType === paymentCollectionTypes.instant) {
        return [
            { iconName: 'shield-halved', paragraph: Resources.Checkout.PaymentMethodInstantDisclaimerParagraph1 },
            { iconName: 'circle-up', paragraph: Resources.Checkout.PaymentMethodInstantDisclaimerParagraph2 },
            { iconName: 'lock', paragraph: Resources.Checkout.PaymentMethodInstantDisclaimerParagraph3 },
            { iconName: 'clock-rotate-left', paragraph: Resources.Checkout.PaymentMethodInstantDisclaimerParagraph4 },
            { iconName: 'undo', paragraph: Resources.Checkout.PaymentMethodInstantDisclaimerParagraph5 },
        ];
    }

    return [
        { iconName: 'shield-halved', paragraph: Resources.Checkout.PaymentMethodDeferredDisclaimerParagraph },
    ];
}