var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-floating-message gfu-floating-notification-box gfu-floating-notification-box--fixed _pa-0 _ma-0 _bgc--transparent _bxsh--none",
        _vm.themeClassName,
      ],
    },
    [
      _c("reverse-language-notification"),
      _vm._v(" "),
      _vm.isCookieBannerVisible
        ? _c("cookie-notification", {
            attrs: { "privacy-settings-url": _vm.privacySettingsUrl },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }